<template>
  <div class="ui-empty">
    <div class="ui-empty-contents">
      <slot name="default">
        <div class="ui-empty-default">
          <img
            :src="image"
            :alt="text"
          />
          <p class="ui-label">{{ text }}</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import tumbleweed from './tumbleweed.svg';

export default {
  name: 'ui-empty',

  props: {
    text: {
      type: String,
      required: false,
      default: 'No hay nada aquí'
    }
  },

  data() {
    return {
      image: tumbleweed
    };
  }
};
</script>

<style lang="scss">
.ui-empty {
  width: 100%;
  min-height: 400px;

  opacity: 0.75;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ui-empty-default {
    text-align: center;
    width: 600px;
    max-width: 90vw;

    img {
      display: block;
      width: 100%;
    }

    p {
      font-size: 1em;
    }
  }
}
</style>